import React from 'react'
import type { LinkResult } from '../data/content/LinkFragment'
import s from './Header.module.sass'
import { Link } from './Link'

export default function Header(props: { title?: string; name?: string; instagram?: LinkResult }) {
	return (
		<div className={s.Header}>
			<div className={s.HeaderBox}>
				<h1 className={s.Title}>{props.title}</h1>
				<div className={s.ButtonBox}>
					<Link link={props.instagram} className={s.Button}>
						<span className={s.ButtonName}>{props.instagram?.title}</span>
					</Link>
				</div>
			</div>
			<h3 className={s.Name}>{props.name}</h3>
		</div>
	)
}
