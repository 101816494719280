import ContemberImage from './ContemberImage'
import s from './Form.module.sass'
import type { HomeProps } from './HomePage'

// const api = createFetcher<API>('/api/v1/[handler]')

export default function Form(props: { data: NonNullable<HomeProps['homePage']['form']> }) {
	// const mutation = useMutation(async (formData: FormData) => {
	// 	const files = formData.getAll('files')
	// 	const data = Object.fromEntries(formData.entries())

	// 	const uploads = await uploadFiles(
	// 		ensureFiles(files).filter((f) => f && f.name),
	// 		(data) => api('presignUpload', data)
	// 	)
	// 	const validatedData = validateZod(
	// 		{
	// 			...data,
	// 			files: uploads
	// 				.map((upload) => upload.key)
	// 				.filter((key): key is string => typeof key === 'string'),
	// 		},
	// 		ContactInput()
	// 	)

	// 	const result = await api('contactForm', validatedData)

	// 	return result.db && result.mail
	// })

	return (
		<>
			<h1 className={s.Title}>{props.data.title}</h1>
			<div className={s.Form}>
				<div className={s.LeftBox}>
					{/* <form
						className={s.ContactForm}
						onSubmit={(event) => {
							event.preventDefault()
							mutation.mutate(new FormData(event.currentTarget))
						}}>
						<fieldset className={s.Fieldset} disabled={mutation.isLoading}>
							<input
								className={s.Input}
								type="text"
								name="name"
								placeholder={props.data.name}
								required
							/>
							<br />
							<input
								className={s.Input}
								type="text"
								name="contact"
								placeholder={props.data.contact}
								required
							/>
							<br />
							<input
								className={s.Input}
								type="text"
								name="motive"
								placeholder={props.data.motive}
							/>
							<br />
							<input className={s.Input} type="text" name="place" placeholder={props.data.place} />
							<br />
							<input className={s.Input} type="text" name="size" placeholder={props.data.size} />
							<br />
							<input className={s.Input} type="text" name="color" placeholder={props.data.color} />
							<br />
							<p className={s.Note}>{props.data.note}</p>
							<UploadControl name="files" label={props.data.uploader} />

							<textarea
								className={clsx(s.Input, s.Message)}
								rows={4}
								name="message"
								placeholder={props.data.message}
							/>

							{mutation.data === null && <div data-status="failure">&nbsp;</div>}

							{mutation.data === false && (
								<div data-status="failure">
									<RichTextRenderer source={props.data.submitFailureMessage ?? null} />
								</div>
							)}
							{mutation.data && (
								<div data-status="success">
									<RichTextRenderer source={props.data.submitSuccessMessage ?? null} />
								</div>
							)}

							<button className={s.SubmitBtn} type="submit">
								{mutation.isLoading ? 'Odesílám…' : props.data.submit}
							</button>
						</fieldset>
					</form> */}
					<p>
						Kontaktujte mě prosím na{' '}
						<a className={s.Email} href="mailto:oyasumi.kitsune.ink@gmail.com">
							oyasumi.kitsune.ink@gmail.com
						</a>
					</p>
				</div>
				<div className={s.RightBox}>
					<ContemberImage image={props.data.image} sizes={'50vw'} />
				</div>
			</div>
		</>
	)
}
