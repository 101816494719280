import type { ReferenceRendererProps } from '@contember/react-client'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import ContemberImage from '../../ContemberImage'
import s from './LeadParagraph.module.sass'

export function LeadParagraph({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div id={`section-${reference.block?.order}`} className={s.LeadParagraph}>
			<span className={s.Image}>
				<ContemberImage
					image={reference.image}
					sizes={['(min-width: 475px) 412px ', '25vw'].join()}
				/>
			</span>
			<p className={s.Text}>{reference.primaryText}</p>
		</div>
	)
}
