import type { ReferenceRendererProps } from '@contember/react-client'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import ContemberImage from '../../ContemberImage'
import s from './Image.module.sass'

export function Image({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div id={`section-${reference.block?.order}`} className={s.Image}>
			<ContemberImage image={reference.image} />
		</div>
	)
}
