import Image from 'next/image'
import React from 'react'
import type { ImageResult } from '../data/content/ImageFragment'

export default function ContemberImage(props: { image?: ImageResult; sizes?: string }) {
	return props.image?.url ? (
		<Image
			src={props.image?.url}
			alt=""
			width={props.image.width}
			height={props.image.height}
			sizes={props.sizes}
		/>
	) : null
}
