import { RichTextRenderer } from '@contember/react-client'
import cn from 'clsx'
import React from 'react'
import type { ContentResult } from '../../data/content/ContentFragment'
import s from './BlockRenderer.module.sass'
import * as referenceRenderersSource from './referenceRenderers'

// adapt React components with capitalized name to blocks names
const referenceRenderers = Object.fromEntries(
	Object.entries(referenceRenderersSource).map(([k, v]) => [
		k.charAt(0).toLowerCase() + k.slice(1),
		v,
	])
)

export function BlockWrapper(props: { textual?: boolean; children: React.ReactNode }) {
	return (
		<div className={cn(s.Block, props.textual && s.Textual)}>
			<div className={s.In}>{props.children}</div>
		</div>
	)
}

export function BlockRenderer(props: {
	className?: string
	inClassName?: string
	blocks: ContentResult['blocks']
	sourceField?: string
	wrappedBlocks?: string[]
	textualWrapper?: React.ComponentType<{ children: React.ReactNode; textual?: boolean }>
	blockWrappers?: Record<string, React.ComponentType<{ children: React.ReactNode }> | undefined>
}) {
	;(global as unknown as { __DEV_MODE__: boolean }).__DEV_MODE__ =
		process.env.NODE_ENV === 'development'
	return (
		<div className={cn(s.Content, props.className)}>
			<div className={s.ContentIn}>
				<RichTextRenderer
					blocks={props.blocks}
					sourceField={props.sourceField ?? 'json'}
					referenceRenderers={referenceRenderers}
					renderElement={(el) => {
						if (el.element.type === 'listItem') {
							return el.fallback
						}
						const CustomWrapper = el.referenceType
							? props.blockWrappers?.[el.referenceType]
							: undefined

						if (CustomWrapper) {
							return <CustomWrapper>{el.fallback}</CustomWrapper>
						}

						const isTextual = el.element.type !== 'reference'

						const isWrapped = (props.wrappedBlocks ?? []).indexOf(el.referenceType ?? '') > -1

						if (isTextual || isWrapped) {
							const TextualBlock = props.textualWrapper ?? BlockWrapper
							return <TextualBlock textual={isTextual}>{el.fallback}</TextualBlock>
						}

						return el.fallback
					}}
				/>
			</div>
		</div>
	)
}
