import React from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'
import BackgroundImages from './BackgroundImages'
import { BlockRenderer } from './blocks/BlockRenderer'
import CoverImages from './CoverImages'
import Form from './Form'
import Header from './Header'
import s from './HomePage.module.sass'

export type HomeProps = {
	homePage: NonNullable<NonNullable<CommonPageProps['data']['getLinkable']>['homePage']>
}

export default function Home(props: CommonPageProps & HomeProps) {
	return (
		<div>
			<Header
				title={props.homePage.title}
				name={props.homePage.name}
				instagram={props.homePage.instagram}
			/>

			<BackgroundImages
				image1={props.homePage.backgroundImageFirst}
				image2={props.homePage.backgroundImageSecond}
			/>

			<CoverImages imageText={props.homePage.coverTextImage} image={props.homePage.coverImage} />

			<div className={s.Content}>
				<BlockRenderer blocks={props.homePage.content?.blocks ?? []} />
			</div>
			{props.homePage.form && <Form data={props.homePage.form} />}
		</div>
	)
}
