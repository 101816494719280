import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import React from 'react'
import HomePage from '../app/components/HomePage'
import { combineUrl } from '../app/data/combineUrl'
import { ContentFragment } from '../app/data/content/ContentFragment'
import { ImageFragment } from '../app/data/content/ImageFragment'
import { LinkFragment } from '../app/data/content/LinkFragment'
import { Thunder } from '../generated/content'
import { serverPropsHandler } from '../packages/@necktip/next/api/handlers'
import { getZeusConnection } from '../packages/@necktip/next/loaders/graphql'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage(props: CommonPageProps) {
	const homePage = props.data.getLinkable?.homePage

	return (
		<div>
			<Head>
				<title>{homePage?.title ?? 'Oyasumi kitsune ink'}</title>
				<link rel="icon" href="/favicon.ico" />
				<meta key="nextpublictest" name="test" content={String(process.env.NEXT_PUBLIC_TEST)} />
			</Head>
			{homePage && <HomePage {...props} homePage={homePage} />}

			{/* <section>
				<h1>
					Props from <code>getServerSideProps</code>
				</h1>
				<Dump data={props} />
			</section>

			<section>
				<h1>
					Server-side number sum with <code>/api/v1/[handler]</code>
				</h1>
				<SumForm />
			</section> */}
		</div>
	)
}

export const getServerSideProps = serverPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	//const locale = context.locale ?? context.defaultLocale ?? 'cs'

	const urlInfo = combineUrl(context)

	const data = await content.query({
		getLinkable: [
			{
				by: {
					url: urlInfo.path,
				},
			},
			{
				id: true,
				url: true,
				homePage: [
					{},
					{
						title: true,
						name: true,
						instagram: [{}, LinkFragment()],
						backgroundImageFirst: [{}, ImageFragment()],
						backgroundImageSecond: [{}, ImageFragment()],
						coverTextImage: [{}, ImageFragment()],
						coverImage: [{}, ImageFragment()],
						content: [{}, ContentFragment()],
						form: [
							{},
							{
								title: true,
								name: true,
								contact: true,
								motive: true,
								place: true,
								size: true,
								color: true,
								note: true,
								uploader: true,
								message: true,
								submit: true,
								submitSuccessMessage: true,
								submitFailureMessage: true,
								image: [{}, ImageFragment()],
							},
						],
					},
				],
			},
		],
	})

	return {
		props: {
			data,
			page: context.params?.page ?? null,
			serverTime: new Date().toString(),
		},
	}
})
