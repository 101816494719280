import React from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import s from './BackgroundImages.module.sass'
import ContemberImage from './ContemberImage'

export default function BackgroundImages(props: { image1?: ImageResult; image2?: ImageResult }) {
	return (
		<div className={s.BackgroundImages}>
			<div className={s.BackgroundImage}>
				<ContemberImage
					image={props.image1}
					sizes={['(min-width: 1100px) 440px ', '50vw'].join()}
				/>
			</div>
			<div className={s.BackgroundImage}>
				<ContemberImage
					image={props.image2}
					sizes={['(min-width: 1100px) 440px ', '50vw'].join()}
				/>
			</div>
		</div>
	)
}
