import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import ContemberImage from '../../ContemberImage'
import s from './Gallery.module.sass'

const sizesSet = {
	0: '500px',
	1: '100px',
	2: '200px',
	3: '500px',
}

export function Gallery({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div id={`section-${reference.block?.order}`} className={s.Gallery}>
			{reference.gallery.map((item, index) => {
				const className = s[`nth${index}`]
				const sizes = index in sizesSet ? sizesSet[index as keyof typeof sizesSet] : '100vw'

				return (
					<div key={item.id} className={clsx(s.ImageContainer, className)}>
						<div className={s.Image}>
							<ContemberImage image={item.image} sizes={sizes} />
						</div>
					</div>
				)
			})}
		</div>
	)
}
