import NextLink from 'next/link'
import React from 'react'
import type { LinkResult } from '../data/content/LinkFragment'

type LinkProps = {
	link?: LinkResult
	className?: string
	gradient?: boolean
	down?: boolean
	nonExternal?: boolean
	children?: React.ReactNode
}

export function Link(props: LinkProps) {
	if (props.link?.externalLink || props.link?.internalLink) {
		const external = Boolean(props.link.externalLink)
		const url = (
			props.link.internalLink?.url ? props.link.internalLink.url : props.link.externalLink
		) as string
		return (
			<NextLink href={url}>
				<a
					className={props.className ?? 'btn'}
					target={external && !props.nonExternal ? '_blank' : ''}>
					{props.children ?? props.link.title}
				</a>
			</NextLink>
		)
	} else {
		return null
	}
}
