import React from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import ContemberImage from './ContemberImage'
import s from './CoverImages.module.sass'

export default function CoverImages(props: { imageText?: ImageResult; image?: ImageResult }) {
	return (
		<div className={s.CoverImages}>
			<div className={s.Image}>
				<ContemberImage image={props.image} sizes={'100vw'} />
			</div>
			<div className={s.ImageText}>
				<ContemberImage
					image={props.imageText}
					sizes={['(min-width: 800px) 192px ', '100vw'].join()}
				/>
			</div>
		</div>
	)
}
