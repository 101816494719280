import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import ContemberImage from '../../ContemberImage'
import s from './InstagramSection.module.sass'

export function InstagramSection({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div id={`section-${reference.block?.order}`} className={s.InstagramSection}>
			<p className={s.Title}>
				<RichTextRenderer source={reference.primaryText ?? null} />
			</p>
			<div className={s.Images}>
				{reference.gallery.map((item, index) => {
					const className = s[`nth${index}`]
					return (
						<div key={item.id} className={clsx(s.ImageBox, className)}>
							<div className={s.Image}>
								<ContemberImage
									image={item.image}
									sizes={['(min-width: 1400px) 228px', '(min-width: 992px) 20vw', '100vw'].join(
										','
									)}
								/>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}
